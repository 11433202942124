body {
  background-color: #e3e2d2;
  font-family: FishardsSmall;
  margin: 0;
}

@font-face {
  font-family: "FishardsBig";
  src: local("FishardsBig"),
    url(./assets/fonts/fishards_big_font.ttf) format("truetype");
}

@font-face {
  font-family: "FishardsMedium";
  src: local("FishardsMedium"),
    url(./assets/fonts/fishardsmedium.ttf) format("truetype");
}

@font-face {
  font-family: "FishardsSmall";
  src: local("FishardsSmall"),
    url(./assets/fonts/TinyUnicode.ttf) format("truetype");
}
